// eslint-disable-next-line no-restricted-imports
import { LogoutOptions, useAuth0 } from '@auth0/auth0-react'
import * as React from 'react'
import {
  setTokenCookie,
  userHasGoalsCookieName,
  userHasNonFreeMembershipCookieName,
} from '../../../common/constants/cookies'
import { sessionWasAuthedCookieName } from '../../../common/constants/cookies'
import merge from 'lodash/merge'
import { useCookies } from '../../../hooks/useCookies'
import { clearSurveySessionStorageData } from '../../recommendations/common'
import { analytics } from '../../analytics/analytics'

const clearAuthOLocalStorageData = () => {
  if (typeof window === 'undefined') return
  // Loop through each key in localStorage
  Object.keys(localStorage).forEach((key) => {
    // Check if the key is related to Auth0
    if (['@@auth0', 'ajs'].some((x) => key.includes(x))) {
      localStorage.removeItem(key)
    }
  })
}

export const useLogOut = () => {
  const { remove } = useCookies()
  const auth0Logout = useAuth0().logout
  return React.useCallback(
    async (options?: LogoutOptions | undefined) => {
      analytics.reset()
      remove(sessionWasAuthedCookieName)
      clearSurveySessionStorageData()
      setTokenCookie()
      remove(userHasGoalsCookieName)
      remove(userHasNonFreeMembershipCookieName)
      clearAuthOLocalStorageData()
      const mergedOptions = merge(
        {
          returnTo: window.location.origin,
        },
        options
      )
      return auth0Logout(mergedOptions)
    },
    [auth0Logout, remove]
  )
}
