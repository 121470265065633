import { getOperationName, isQueryOperation, isSubscriptionOperation } from '@apollo/client/utilities'
import { useAccount } from '../../../../features/auth/hooks/useAccount'
import { DocumentNode } from 'graphql'
// !!return to this when hasura accurately gets auth permissioning data from nest into schema
// import validAnonymousOperations from '../../generated/valid-anonymous-operations.json'

const validAnonymousOperations = [
  'CartDetails',
  'UpdateCart',
  'GetCart',
  'LabProducts',
  'FeaturedPackages',
  'GetCirclesByInvite',
  'CircleContentByInviteShortId',
  'CircleIdByInviteShortId',
  'ActionItemDetails',
  'StripeProductCmsData',
  'KpiMetricDetails',
  'LabTestMarkerDetails',
  'PdpContent',
  'SupplementProducts',
  'ProductTypeDefaultImage',
  'CommercePage',
  'ShopPageFeaturedProducts',
  'GetMetricCalculators',
  'RunCalculation',
  'OnboardingSurvey',
  'AllGoals',
  'MetricDetailsCmsContent',
  'AddOnsBySlugs',
  'MembershipProducts',
  'GetFeaturedPackages',
  'Tos',
  'HasCompletedSurvey',
  'HelpPageFaq',
  'MembersHomePageBannerProducts',
  'LabContent',
  'PartnerProductsList',
  'ProductIdAndTypeBySlug',
  // IN PROGRESS. VERIFY ULF QUERY LIST AND THEN DELETE FROM useFranchiseMembership.ts
  'SetFranchiseLocation',
  'GetFranchiseServices',
  'FranchiseLocations',
  'WordPressFranchiseLocationsData',
  'CreateBookingDate',
  'GetBookingSlotsForBookedDate',
  'GetFranchiseMemberships',
  'GetFranchisePackages',
]
const unauthenticatedQueriesWhitelist = new Set<string>(validAnonymousOperations)

// TODO make this dynamic and have it come from an introspection query, and then traverse the fields, and make sure that
// all field resolvers in incoming query are available to the unauthed user
/**
 * while authentication is in progress, we want to skip any queries which aren't whitelisted as unauthed queries so as
 * to avoid netowrk errors.
 */
export function useSkipNonWhitelistedUnauthenticatedRequests<T extends { skip?: boolean }>(
  documentNode: DocumentNode,
  options: T
) {
  const isAuthenticated = useAccount().isAuthenticated
  if (!isQueryOperation(documentNode) && !isSubscriptionOperation(documentNode)) return options
  const operationName = getOperationName(documentNode)
  if (!isAuthenticated && operationName && !unauthenticatedQueriesWhitelist.has(operationName)) {
    options.skip = true
  }
  return options
}
