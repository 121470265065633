type CustomRequestInit = Omit<RequestInit, 'body'> & { body?: any }
type FetchOptions = CustomRequestInit | undefined

export const makeErrorMessage = (error: any, endpoint: string) => {
  const baseErrorMessage = `Request Error: ${endpoint}`

  const errorMessage = !error
    ? ''
    : typeof error === 'object' && typeof error.toString === 'function'
      ? error.toString()
      : typeof error === 'string'
        ? error
        : JSON.stringify(error)
  const joiner = errorMessage ? ' -- ' : ''
  return `${baseErrorMessage}${joiner}${errorMessage}`
}

export async function restClient<T = unknown>(
  endpoint: string,
  { body, ...customConfig }: FetchOptions = {},
  // there is an api route whose error we cannot stop and rejecting the promise causes a log that is clogging our error
  // logs ven when we catch it. add an option to skip
  skipPromiseRejection?: boolean
): Promise<T | null> {
  try {
    const headers = { 'Content-Type': 'application/json' }

    const config = {
      method: body ? 'POST' : 'GET',
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
      ...(body && { body: typeof body === 'object' ? JSON.stringify(body) : body }),
    }

    const response = await fetch(endpoint, config)
    if (response.ok) {
      return await response.json()
    } else {
      if (skipPromiseRejection) return null
      const errorMessage = await response.text()
      const finalMessage = makeErrorMessage(errorMessage, endpoint)

      return Promise.reject(new Error(finalMessage))
    }
  } catch (error: any) {
    if (skipPromiseRejection) return null
    return Promise.reject(makeErrorMessage(error, endpoint))
  }
}
