import { Operation, split } from '@apollo/client'
import { clientNameContextKey, useAdminContextKey } from '../../../common/constants/apolloContextKeys'
import { realizeAdminSpoofedUserSessionStorageKey } from '../../../common/constants/sessionStorage'
import {
  adminGraphqlLink,
  adminRelayLink,
  nonAdminHttpLink,
  nonAdminRelayLink,
  nonAdminFranchiseLink,
} from './httpLinks'
import { makeSubscriptionLink } from './makeSubscriptionLink'
import { relayClient, franchiseClient } from '../../../common/constants/apolloContextClientNames'
import { isSubscriptionOperation } from '@apollo/client/utilities'

// TODO need to work on our server side auth. currently, all of this is reliant on client side data. need to add cookie
// etc and use this in place of localstorage on server

const isRelay = (operation: Operation) => operation.getContext()[clientNameContextKey] === relayClient
const isFranchise = (operation: Operation) => operation.getContext()[clientNameContextKey] === franchiseClient

const isAdminRequest = (operation: Operation) => {
  const context = operation.getContext()
  const isAdmin =
    context[useAdminContextKey] ||
    (typeof window !== 'undefined' && !!sessionStorage.getItem(realizeAdminSpoofedUserSessionStorageKey))

  return isAdmin
}

const makeAdminLinks = (wsUrl?: string) =>
  split(
    ({ query }) => isSubscriptionOperation(query),
    makeSubscriptionLink(wsUrl),
    split((operation) => isRelay(operation), adminRelayLink, adminGraphqlLink)
  )

const standardLinks = split(
  ({ query }) => isSubscriptionOperation(query),
  makeSubscriptionLink(),
  split(
    (operation) => isRelay(operation),
    nonAdminRelayLink,
    split((operation) => isFranchise(operation), nonAdminFranchiseLink, nonAdminHttpLink)
  )
)

export const makeConnectionLink = (wsUrl?: string) => split(isAdminRequest, makeAdminLinks(wsUrl), standardLinks)
