import * as React from 'react'
import { useDevTools } from '../../hooks/useDevTools'
import { usePrevious } from '../../hooks/usePrevious'
import {
  appendUserIdToClientAdminWsEndpoint,
  clientNonAdminWsEndpoint,
} from '../../common/constants/envDependentRoutes'
import { ApolloNextAppProvider } from '@apollo/experimental-nextjs-app-support'
import { getMakeApollo, MakeApolloOptions } from './getMakeApollo'

type ContextValue = { reinitApolloClient: (options?: MakeApolloOptions) => void } | undefined

const Context = React.createContext<ContextValue>(undefined)

export function ApolloProvider({ children }: React.PropsWithChildren) {
  const [currentMakeapollo, setCurrentMakeApollo] = React.useState(getMakeApollo)

  const [{ apiAccountOverride }] = useDevTools()

  const accountOverrideId = apiAccountOverride?.id

  const reinitApolloClient = React.useCallback(
    (clientOptions?: MakeApolloOptions) => {
      return setCurrentMakeApollo(
        getMakeApollo({
          wsUrl: accountOverrideId ? appendUserIdToClientAdminWsEndpoint(accountOverrideId) : clientNonAdminWsEndpoint,
          ...clientOptions,
        })
      )
    },
    [accountOverrideId]
  )

  const previousApiAccountOverrideId = usePrevious(accountOverrideId)

  React.useEffect(() => {
    if (accountOverrideId !== previousApiAccountOverrideId) {
      reinitApolloClient()
    }
  }, [accountOverrideId, previousApiAccountOverrideId, reinitApolloClient])
  return (
    <Context.Provider value={{ reinitApolloClient }}>
      <ApolloNextAppProvider makeClient={currentMakeapollo}>{children}</ApolloNextAppProvider>
    </Context.Provider>
  )
}

export const useReinitApolloClient = () => {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error("'useReinitApolloClient' must be used within a ReinitializableApolloProvider")
  }
  return context
}
