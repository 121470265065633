import { breakpoints } from './breakpoints'

const { phone, tablet, notebook, desktop } = breakpoints

const makeCssMq = (queryString: string) => `@media only screen and ${queryString}`

type Min = { min: number }
type Max = { max: number }
type MinMax = Min & Max

/**
 * media query string maker function. takes in min, max, or both values (in object input) and creates the appropriate media query. We will
 * see if this becomes useful at all. more declarative and easier to use in terms of naming than the current methods we
 * are using, but less easy than just a single import
 */
export function mq(minInput: Min): string
export function mq(maxInput: Max): string
export function mq(minMaxInput: MinMax): string
export function mq(input: Min | Max | MinMax) {
  const min = 'min' in input ? `(min-width: ${input.min}px)` : ''
  const max = 'max' in input ? `(max-width: ${input.max}px)` : ''
  return makeCssMq([min, max].filter(Boolean).join(' and '))
}

export const canHoverMq = '(hover: hover)'
export const isTouchScreenMq = '(hover: none)'
export const isPortraitMq = '(orientation: portrait)'

export const phoneDownMq = mq({ max: phone })
export const phoneUpMq = mq({ min: phone })
export const tabletDownMq = mq({ max: tablet })
export const tabletUpMq = mq({ min: tablet })
export const tabletToNotebookMq = mq({ min: tablet, max: notebook })
export const notebookDownMq = mq({ max: notebook })
export const notebookUpMq = mq({ min: notebook })
export const desktopDownMq = mq({ max: desktop })
export const desktopUpMq = mq({ min: desktop })

export const canHoverCssMq = makeCssMq(canHoverMq)
export const isTouchScreenCssMq = makeCssMq(isTouchScreenMq)

export const alwaysFalseyMq = mq({ min: 1, max: 0 })
export const alwaysTruthyMq = `@media`
