import { setContext } from '@apollo/client/link/context'
import { DANGEROUSLY_ACCESS_AUTH0_CONTEXT_OUTSIDE_TREE } from '../../../features/Auth0ExternalUsageModuleSetter' // eslint-disable-line no-restricted-imports
import { setTokenCookie } from '../../../common/constants/cookies'

export const authLink = setContext(async (_operation, { headers, ...context }) => {
  const auth0Context = DANGEROUSLY_ACCESS_AUTH0_CONTEXT_OUTSIDE_TREE
  const token = auth0Context?.isAuthenticated && (await auth0Context.getAccessTokenSilently?.())

  setTokenCookie(token)

  return {
    headers: {
      ...headers,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
    },
    ...context,
    user: auth0Context?.user,
  }
})
