import { Auth0ContextInterface, useAuth0, User } from '@auth0/auth0-react' // eslint-disable-line no-restricted-imports

// !! use this VERY sparingly. better to use useAuth0/useAccount if the functionality is within the component tree. Use only if you
// absolutely need to access auth0 context while outside the component tree such as in apollo links
type NullableAuth0Context = Auth0ContextInterface<User> | undefined
export let DANGEROUSLY_ACCESS_AUTH0_CONTEXT_OUTSIDE_TREE: NullableAuth0Context

export function Auth0ExternalUsageModuleSetter() {
  const auth0Context = useAuth0()
  DANGEROUSLY_ACCESS_AUTH0_CONTEXT_OUTSIDE_TREE = auth0Context
  return null
}
