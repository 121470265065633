export async function wait(waitInMs: number) {
  return new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve(undefined)
      }, waitInMs)
    } catch (error) {
      reject(error)
    }
  })
}
