import * as React from 'react'
import merge from 'lodash/merge'
import { xHasuraRoleHeader } from '../../../../common/constants/headers'
import { Role } from '../../../../common/constants/roles'
import { RoleContext } from '../../../../features/auth/providers/RoleProvider'
import { useAccount } from '../../../../features/auth/hooks/useAccount'

export function useRoleOptions<T>(options: T): T {
  const roles = React.useContext(RoleContext)
  const { user } = useAccount()

  if (!roles.length || [Role.anonymous, Role.user].some((role) => roles.includes(role))) {
    // !!adding x-hasura-* headers break requests that go directly to nest/hasura, so don't add the hasura role header
    // here unless you strip it from the apollo links which don't hit our graph pass through (nonadmin links at the time
    // of writing)
    return options
  }

  const matchingRole = user.getMatchingRole(roles)

  return merge(
    // override fetchPolicy which will likely be set as cache-and-network
    { ...options, fetchPolicy: 'no-cache' },
    {
      context: {
        headers: {
          [xHasuraRoleHeader]: matchingRole,
        },
      },
    }
  )
}
