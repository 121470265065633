import styled from '@emotion/styled'
import { canHoverCssMq } from '../../styles/mediaQueries'

// TODO switch all :hover css to what we have below. make specific selector var to use
/* need to disable this on devices that can't hover (touchscreens) otherwise tapping on touch screen devices triggers
hover and does not get set to not hovering when tap is complete */
export const hoverOpacity = `
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease-out;


${canHoverCssMq} {
  :hover:not(:disabled) {
    opacity: 0.6;
  }
}
`

export const HoverOpacityWrapper = styled.span`
  ${hoverOpacity}
`
