'use client'
import React from 'react'

/**
 * track an input and return what it was on the previous render. optional second argument to indicate whether we want to
 * freeze a value at a specific time and stop updating it (useful if you are only interested in changes up to a certain
 * point, and then want to keep the value until another change meeting the condition registers as true)
 */
export function usePrevious<T>(value: T, freeze?: boolean): T {
  const ref = React.useRef<T>()
  React.useEffect(() => {
    if (!freeze) ref.current = value
  }, [value, freeze])
  return ref.current as T
}
