import { recommendationsRoute } from '../../common/constants/routes'

import {
  activeOnboardingSurveySlideIndexSessionStorageKey,
  stopTrackingSurveyStepUrlSessionStorageKey,
  persistedOnboardingSurveyDataSessionStorageKey,
  surveySkippedGoalsDataSessionStorageKey,
  userRetakingSurveySessionStorageKey,
} from '../../common/constants/sessionStorage'

export const clearSurveySessionStorageData = ({ exceptions }: { exceptions: string[] } = { exceptions: [] }) => {
  return (
    typeof window !== 'undefined' &&
    [
      persistedOnboardingSurveyDataSessionStorageKey,
      activeOnboardingSurveySlideIndexSessionStorageKey,
      surveySkippedGoalsDataSessionStorageKey,
      stopTrackingSurveyStepUrlSessionStorageKey,
      userRetakingSurveySessionStorageKey,
    ]
      .filter((x) => !exceptions.includes(x))
      .map((x) => sessionStorage.removeItem(x))
  )
}

export const stepQueryParamName = 'step'

export enum onboardingRecommendationSteps {
  survey = 'survey',
  goals = 'goals',
  personalInfo = 'personalInfo',
  recommendations = 'recommendations',
}

const makeRecommendationsStepUrl = (step: onboardingRecommendationSteps) =>
  `${recommendationsRoute}?${stepQueryParamName}=${step}`

export const surveyScreenUrl = makeRecommendationsStepUrl(onboardingRecommendationSteps.survey)
export const goalsScreenUrl = makeRecommendationsStepUrl(onboardingRecommendationSteps.goals)
export const personalInfoScreenUrl = makeRecommendationsStepUrl(onboardingRecommendationSteps.personalInfo)
export const reommendationsScreenUrl = makeRecommendationsStepUrl(onboardingRecommendationSteps.recommendations)
