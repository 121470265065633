// TODO figure out if this is exactly what we want, how to compose this in more complex situations (ex. what if
// component already has a transition. this woudl overwrite that when we actualy just want to add this as an additional
// transition), and use in the areas we already have disabled/loading style interactions

// TODO move things from common/style into this src/styles folder (or vice versa)
export const loadingDisabledBaseStyles = (p: { $loading?: boolean }) => {
  return `
  :disabled {
    cursor: ${p.$loading ? 'progress' : 'not-allowed'};
  }
  opacity: ${p.$loading ? 0.5 : 1};
  transition: opacity 0.3s;
  cursor: ${p.$loading ? 'progress' : 'pointer'};
  `
}
