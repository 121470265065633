import { FieldMergeFunction, InMemoryCacheConfig } from '@apollo/client'
import { KeyArgsFunction } from '@apollo/client/cache/inmemory/policies'
import { relayStylePagination } from '@apollo/client/utilities'
import { isRealNumber } from '../../typescript/guards/isRealNumber'
import { atob } from '../../utilities/base64'

const paginationFieldAliasBeginning = 'paginated_'
// when we are paginating a query, have the alias start with 'paginated_'. if so, we want to remove 'limit'
// and 'offset' from the cache keys of those queries and leave everything else as a key so any change in
// non-pagination args gets cached separately, but calls whose args only differ in limit or offset get merged
// with the existing cache for the remaining key args.
const keyArgsHandler: KeyArgsFunction = function keyArgs(args, context) {
  const alias = context.field?.alias?.value
  const isPaginated =
    alias?.includes(paginationFieldAliasBeginning) &&
    alias.replace(paginationFieldAliasBeginning, '') === context.fieldName

  const argsArr = Object.keys(args ?? {})

  if (isPaginated) return argsArr.filter((x) => x !== 'offset')
  return argsArr
}

const mergeHandler: FieldMergeFunction = function merge(existing, incoming, { args }) {
  const offset = args && isRealNumber(args.offset) ? args.offset : 0
  const merged = existing?.slice() ?? []
  for (let i = 0; i < incoming.length; ++i) {
    merged[offset + i] = incoming[i]
  }

  return merged
}

const paginationOptions = {
  keyArgs: keyArgsHandler,
  merge: mergeHandler,
}

// https://hasura.io/docs/latest/schema/postgres/relay-schema/#node-interface
// [<version-integer>, "<table-schema>", "<table-name>", "column-1", "column-2", ... "column-n"]
const parseNodeId = (input: string = ''): string => {
  // assume uuid
  if (input.length === 36) {
    return input
  }

  try {
    const parsed = JSON.parse(atob(input))
    return parsed[3]
  } catch (e) {
    console.error(e)
    return input
  }
}

export const inMemoryCacheOptions: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        franchise: {
          merge: true,
        },
        me_v2: { merge: true },
        cms: { merge: true },
        metric_record_group: paginationOptions,
        metric_record: paginationOptions,
        metric_record_group_connection: relayStylePagination(),
        metric_record_connection: relayStylePagination(),
      },
    },
    // This has to be done for every entity queried through relay, otherwise the ids will be encoded
    metric_record_group: {
      fields: {
        id: {
          read: parseNodeId,
        },
      },
    },
    metric: {
      fields: {
        id: {
          read: parseNodeId,
        },
      },
    },
    metric_record: {
      fields: {
        id: {
          read: parseNodeId,
        },
      },
    },
  },
}
