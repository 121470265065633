import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { DANGEROUSLY_ACCESS_AUTH0_CONTEXT_OUTSIDE_TREE } from '../../../features/Auth0ExternalUsageModuleSetter' // eslint-disable-line no-restricted-imports
import { wait } from '../../../utilities/wait'
import { createHttpLink } from '@apollo/client'
import { clientNonAdminWsEndpoint } from '../../../common/constants/envDependentRoutes'
import { webSocketUrlToHttpUrl } from '../../../utilities/httpWebsocketUrlConverters'
import { clientVersionHeaders } from '../common'

const makeSubscriptionClient = (url: string) => {
  return createClient({
    // need this for the subscriptions to reconnect after disconnects for things like device locks on ios
    shouldRetry: () => true,
    retryAttempts: 10,
    async retryWait(retries) {
      // first retry will be immediate. after that, wait an additional 10 seconds each time
      await wait(retries * (1000 * 10))
    },
    url,
    connectionParams: async () => {
      const auth0Context = DANGEROUSLY_ACCESS_AUTH0_CONTEXT_OUTSIDE_TREE
      const token =
        auth0Context?.isAuthenticated && (await DANGEROUSLY_ACCESS_AUTH0_CONTEXT_OUTSIDE_TREE?.getAccessTokenSilently())
      const authHeaders = { Authorization: `Bearer ${token}` }
      return { headers: { ...authHeaders } }
    },
  })
}

// subscription links error on server (and we don't want them in ssr anyway). just use a regular link in this case by
// swapping the ws with http or using the default graph url if no url overrride is provided
export const makeSubscriptionLink = (url: string = clientNonAdminWsEndpoint) => {
  if (typeof window === 'undefined') {
    return createHttpLink({ uri: webSocketUrlToHttpUrl(url), headers: { ...clientVersionHeaders } })
  }
  return new GraphQLWsLink(makeSubscriptionClient(url))
}
