import styled from '@emotion/styled'
import { loadingDisabledBaseStyles } from '../../styles/loadingDisabledBaseStyles'
import { hoverOpacity } from '../style/HoverOpacityWrapper'
import { transientOptions } from '../style/transientOptions'
import { UnstyledButton } from '../style/UnstyledButton'

export const ButtonBase = styled(UnstyledButton, transientOptions)<{ $loading?: boolean }>`
  ${hoverOpacity}
  ${loadingDisabledBaseStyles}
  /* some svgs get wrapped with a larger button than desired based on expecting trailing character lengths like y would
     have. set line-height to zero so the button wraps the svg exactly */
  line-height: 0;
`
