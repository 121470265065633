import React from 'react'
import { Role } from '../../../common/constants/roles'
import { useAccount } from '../hooks/useAccount'

export type RoleProviderProps = React.PropsWithChildren<{
  roles: Role[]
  altDisplay?: React.ReactNode
}>

export const RoleContext = React.createContext<Role[]>([])

/**
 * Hides component tree when user does not have the required role
 */

export function ShowForRole({ children, roles, altDisplay }: RoleProviderProps) {
  const { user } = useAccount()

  const matchingRole = user.getMatchingRole(roles);
  if (roles.length && !matchingRole) {
    return <>{altDisplay}</>
  }
  return <>{children}</>
}

/**
 * Used to set the role used to make requests with apollo
 */
export const RoleProvider = ({ children, roles, altDisplay }: RoleProviderProps) => {
  return (
    <>
      <RoleContext.Provider value={roles}>
        <ShowForRole roles={roles} altDisplay={altDisplay}>
          {children}
        </ShowForRole>
      </RoleContext.Provider>
    </>
  )
}
