import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const unstyledButtonStyles = css`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  /* safari adds a margin to buttons automatically for some reason */
  margin: 0;
  /*  */
`

export const UnstyledButton = styled.button`
  ${unstyledButtonStyles}
`
