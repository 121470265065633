import { graphqlRouteSubdir, relayRouteSubdir } from './routes'
import { httpUrlToWebSocketUrl } from '../../utilities/httpWebsocketUrlConverters'
import { getPublicEnv } from '../../env/getEnvs'

const publicEnvs = getPublicEnv()

export const originServerUrl =
  typeof window !== 'undefined' ? location.origin : publicEnvs.NEXT_PUBLIC_ORIGIN_SERVER_URL

export const stellateCacheableHttpEndpoint = publicEnvs.NEXT_PUBLIC_STELLATE_CACHEABLE_GQL_HTTP_ENDPOINT

export const hasuraEndpoint = publicEnvs.NEXT_PUBLIC_HASURA_ENDPOINT ?? ''
export const hasuraGqlEndpoint = `${hasuraEndpoint}${graphqlRouteSubdir}`
export const hasuraRelayEndpoint = `${hasuraEndpoint}${relayRouteSubdir}`

// CLIENT NON-ADMIN
export const clientNonAdminWsEndpoint = `${httpUrlToWebSocketUrl(hasuraGqlEndpoint)}`
// some of this seems a bit duplicative (assigning a new variable to the same value as an existing one), but would like
// to be explicit about each item's function via the name as they have tended to drift in the past and i want to be sure
// the correct item is used everywhere even if that happens
export const clientNonAdminRelayEndpoint = hasuraRelayEndpoint
export const clientNonAdminStellateCacheableGqlHttpEndpoint = stellateCacheableHttpEndpoint

// CLIENT ADMIN
export const clientAdminWsEndpoint = `${httpUrlToWebSocketUrl(originServerUrl)}${graphqlRouteSubdir}`
export const clientAdminRelayEndpoint = `${originServerUrl}${relayRouteSubdir}`
export const clientAdminGqlEndpoint = `${originServerUrl}${graphqlRouteSubdir}`

export const appendUserIdToClientAdminWsEndpoint = (userId: string) => `${clientAdminWsEndpoint}?userId=${userId}`

/**
 * @Notes
 */

// URL COMPONENTS

// ws()
// relaySubdir
// graphSubdir
// gqlApiEndpoint
// host

// NEXT_PUBLIC_CACHEABLE_GQL_HTTP_ENDPOINT=https://capi.dev.realizeme.io
// NEXT_PUBLIC_HASURA_ENDPOINT=https://realize-dev.hasura.app

// CLIENT
//    NON-ADMIN:
//      WS:  ws(NEXT_PUBLIC_HASURA_ENDPOINT) + graphSubdir
//      RELAY: NEXT_PUBLIC_HASURA_ENDPOINT + relaySubdir
//      GQL: NEXT_PUBLIC_CACHEABLE_GQL_HTTP_ENDPOINT

// CLIENT
//    ADMIN:
//      WS:  ws(host) + graphSubdir
//      RELAY: host + relaySubdir
//      GQL: host + graphSubdir
