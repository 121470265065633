'use client'
import { DocumentNode } from '@apollo/client'
import { useDevTools } from './useDevTools'
import React from 'react'
import { typesafeEntries } from '../typescript/guards/typesafeEntries'
import { getOperationName } from '@apollo/client/utilities'
import { typedFalsyFilter } from '../typescript/guards/typedFalsyFilter'

export function useAirplaneModeMock(document: DocumentNode, variables: unknown) {
  const [{ airplaneMode, mocks }] = useDevTools()
  const [hasher, setHasher] = React.useState<Function>(() => {})

  React.useEffect(() => {
    let mounted = true
    if (airplaneMode) {
      ;(async () => {
        import('../utilities/md5').then((x) => mounted && setHasher(() => x.md5))
      })()
    }
    return () => {
      mounted = false
    }
  }, [airplaneMode])

  const operationName = getOperationName(document)
  const mockOperationName = `${operationName}Mock`
  const variableHash = hasher?.(variables ?? {})

  const mockKey = `${variableHash}__${mockOperationName}`

  // Fallback to any matching query even if var hash not matching
  const [match] = typesafeEntries(mocks)
    .filter(([k, _v]) => {
      const [, name] = k.split('__')
      return name === mockOperationName
    })
    .map(([_k, v]) => v)
    .filter(typedFalsyFilter)

  const mockResponse = airplaneMode ? mocks[mockKey] ?? match : null

  return { mockResponse }
}
