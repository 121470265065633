// new idea: create helper function to handle the between x and y names that have made the naming convention somewhat unwieldy

enum sizes {
  phone = 'phone',
  tablet = 'tablet',
  notebook = 'notebook',
  desktop = 'desktop',
}
/**
 * the order of the breakpoints/naming is definitely applicable only in the upward direction. phone is 0->480. 480 ->
 * 768 could be phone or tablet. 768 -> 992 could be tablet or small notebook. 992 -> 1200 is notebook, and 1200+ is desktop
 */
export const breakpoints = {
  // phone
  [sizes.phone]: 480,
  // phone/tablet
  [sizes.tablet]: 768,
  // large tablet/small notebook
  [sizes.notebook]: 992,
  // notebook
  [sizes.desktop]: 1200,
  // desktop
}

// maybe switch to things like tablet min/phone max, tablet min/tablet max
