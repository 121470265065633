import { Auth0ContextInterface } from '@auth0/auth0-react'
import * as React from 'react'
import { useDevTools } from '../../../hooks/useDevTools'
import type { RealizeUser } from '../RealizeUser'
import { useLogOut } from './useLogOut'

type Context = ({ user: RealizeUser } & Omit<Auth0ContextInterface, 'user'>) | undefined

export const AccountContext = React.createContext<Context>(undefined)

export const useAccount = () => {
  const [{ airplaneMode }] = useDevTools()
  const accountContext = React.useContext(AccountContext)

  const logout = useLogOut()

  React.useEffect(() => {
    if (accountContext?.error) logout()
  }, [accountContext?.error, logout])

  if (accountContext === undefined) throw new Error("'useAccount' must be used within an AccountProvider")

  if (airplaneMode) {
    // !! do not change this to object spread. for some reason, TS only works with Object.assign
    const user = Object.assign(accountContext.user, { isRealizeAdmin: true })
    return { ...accountContext, user }
  }

  return accountContext
}
