import { AnalyticsBrowser } from '@segment/analytics-next'
import { typesafeEntries } from '../../typescript/guards/typesafeEntries'
import { getPublicEnv } from '../../env/getEnvs'

const TRACK_TIMEOUT = 1000

// create a proxy for the server which just always returns out a function that returns a resolved promise immediately so
// we can always just call this function as normal with no ts issues, but if it actually runs, it handles any method
// analytics has and always allows awaiting the promises
const proxyObj = new Proxy(
  {},
  {
    get: function (...args) {
      const [target, prop] = args
      if (!(prop in target)) {
        return () => Promise.resolve()
      }
      return Reflect.get(...args)
    },
  }
) as unknown as AnalyticsBrowser

export const analytics =
  typeof window === 'undefined'
    ? proxyObj
    : (typesafeEntries(
        AnalyticsBrowser.load(
          { writeKey: getPublicEnv().NEXT_PUBLIC_SEGMENT_WRITE_KEY },
          {
            integrations: { 'Segment.io': { apiHost: 'analytics-events.upgradehealth.com/v1' } },
          }
        )
      ).reduce<AnalyticsBrowser>(
        // @ts-ignore
        (acc, [key, value]) => ({
          ...acc,
          [key]:
            typeof value === 'function'
              ? (...args: Parameters<typeof value>) => {
                  const timeoutPromise = new Promise((resolve) => {
                    setTimeout(() => {
                      resolve(undefined)
                    }, TRACK_TIMEOUT)
                  })

                  return Promise.race([value(...args), timeoutPromise])
                }
              : value,
        }),
        {} as Record<string, any>
      ) as unknown as AnalyticsBrowser)
