import isNil from 'lodash/isNil'
import getConfig from 'next/config'
import { atom } from 'recoil'
import { airplaneModeParamName } from '../../../common/constants/queryParams'
import { airplaneModeSessionStorageKey } from '../../../common/constants/sessionStorage'

export type DevToolsState = {
  apiAccountOverride: { name: string; email: string; id: string } | null
  showDetailedFastingLog: boolean
  airplaneMode: boolean
  mocks: { [key in string]: any }
  writeQueryResponseToDisk: boolean
  networkOffline: boolean
}

export const devToolsState = atom<DevToolsState>({
  key: 'devToolsState',
  default: (() => {
    const networkOffline = typeof window !== 'undefined' && window.navigator.onLine === false
    const airplaneModeQueryParam =
      typeof window !== 'undefined' ? new URLSearchParams(location.search).get(airplaneModeParamName) : null
    const airplaneModeSessionStorage =
      typeof window !== 'undefined' && sessionStorage.getItem(airplaneModeSessionStorageKey) === 'true'

    const airplaneMode =
      airplaneModeSessionStorage ||
      networkOffline ||
      (!isNil(airplaneModeQueryParam) && airplaneModeQueryParam !== 'false')

    return {
      apiAccountOverride: null,
      showDetailedFastingLog: false,
      networkOffline,
      airplaneMode,
      writeQueryResponseToDisk: false,
      // optional chaining here solely because this file is processed when running generate-valid-anonymous-operations
      mocks: getConfig()?.publicRuntimeConfig.mocks ?? {},
    }
  })(),
})
